import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, Clock, Tag, ChevronLeft, ChevronRight } from 'lucide-react';
import { getPosts } from '@/services/api/blog';
import type { BlogPost } from '@/types/blog';
import BlogLayout from '@/layouts/BlogLayout';
import BlogSEO from '@/components/seo/BlogSEO';
import Pagination from '@/components/blog/Pagination';

const POSTS_PER_PAGE = 9;

const BlogPage: React.FC = () => {
    const [posts, setPosts] = useState<BlogPost[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPosts, setTotalPosts] = useState(0);

    useEffect(() => {
        loadPosts();
    }, [currentPage]);

    const loadPosts = async () => {
        setLoading(true);
        try {
            const { data, count, error } = await getPosts({
                page: currentPage,
                limit: POSTS_PER_PAGE,
                status: 'published'
            });

            if (error) throw error;
            setPosts(data || []);
            setTotalPosts(count || 0);
        } catch (error) {
            console.error('Error loading posts:', error);
        } finally {
            setLoading(false);
        }
    };

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 }
    };

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const estimateReadTime = (content: string) => {
        const wordsPerMinute = 200;
        const words = content.trim().split(/\s+/).length;
        const minutes = Math.ceil(words / wordsPerMinute);
        return `${minutes} min read`;
    };

    return (
        <BlogLayout>
            <BlogSEO isBlogList={true} />
            <div className="container mx-auto px-4 py-12">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-center mb-8"
                >
                    <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                        Scholar Tree Blog
                    </h1>
                    <p className="text-xl text-gray-300 max-w-2xl mx-auto">
                        Insights, updates, and educational resources to help you excel in your learning journey.
                    </p>
                </motion.div>

                {/* Top Pagination */}
                <Pagination
                    currentPage={currentPage}
                    totalPosts={totalPosts}
                    postsPerPage={POSTS_PER_PAGE}
                    onPageChange={setCurrentPage}
                    className="mb-8"
                />

                {loading ? (
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <div className="flex justify-center items-center h-64">
                                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                        >
                            {posts.map((post) => (
                                <motion.article
                                    key={post.id}
                                    variants={item}
                                    className="relative h-full group"
                                >
                                    <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 to-accent-500/5 rounded-2xl transform group-hover:scale-[1.02] transition-transform duration-300" />
                                    <div className={`relative h-full rounded-2xl backdrop-blur-xl border border-white/10 
                                        group-hover:border-primary-500/50 group-hover:shadow-xl group-hover:shadow-primary-500/10 
                                        transition-all duration-300 overflow-hidden ${post.featured_image ? 'bg-gray-900' : 'bg-white/5'}`}>
                                        {post.featured_image && (
                                            <div className="absolute inset-0 z-0">
                                                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/80 to-gray-900/60 z-10" />
                                                <img
                                                    src={post.featured_image}
                                                    alt={post.title}
                                                    className="w-full h-full object-cover opacity-40"
                                                />
                                            </div>
                                        )}
                                        <div className="relative z-10 p-8">
                                            {post.categories && post.categories.length > 0 && (
                                                <div className="flex flex-wrap gap-2 mb-4">
                                                    {post.categories.map(category => (
                                                        <span
                                                            key={category.id}
                                                            className="px-3 py-1 bg-primary-500/20 text-primary-300 rounded-full text-sm font-medium"
                                                        >
                                                            {category.name}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                            <Link
                                                to={`/blog/${post.slug}`}
                                                className="block mb-4 group"
                                            >
                                                <h2 className="text-2xl font-bold text-white group-hover:text-primary-400 transition-colors duration-200">
                                                    {post.title}
                                                </h2>
                                            </Link>
                                            <p className="text-gray-300 mb-6 line-clamp-3 text-base">
                                                {post.excerpt || post.content.slice(0, 150)}...
                                            </p>
                                            <div className="flex items-center justify-between text-sm text-gray-400">
                                                <div className="flex items-center gap-4">
                                                    <span className="flex items-center gap-1.5">
                                                        <Calendar className="w-4 h-4" />
                                                        {formatDate(post.published_at || post.created_at)}
                                                    </span>
                                                    <span className="flex items-center gap-1.5">
                                                        <Clock className="w-4 h-4" />
                                                        {estimateReadTime(post.content)}
                                                    </span>
                                                </div>
                                                {post.tags && post.tags.length > 0 && (
                                                    <span className="flex items-center gap-1.5">
                                                        <Tag className="w-4 h-4" />
                                                        {post.tags[0]}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </motion.article>
                            ))}
                        </motion.div>

                        {posts.length === 0 && (
                            <div className="text-center py-12">
                                <p className="text-gray-400">No posts found</p>
                            </div>
                        )}

                        {/* Bottom Pagination */}
                        <Pagination
                            currentPage={currentPage}
                            totalPosts={totalPosts}
                            postsPerPage={POSTS_PER_PAGE}
                            onPageChange={setCurrentPage}
                            className="mt-16"
                        />
                    </>
                )}
            </div>
        </BlogLayout>
    );
};

export default BlogPage;
