import { supabase } from '@/lib/supabaseClient';

export const adminStorageApi = {
  async uploadFile(file: File, folder: string = 'admin_files') {
    try {
      // Get the original file name and extension
      const originalName = file.name;
      const fileExt = originalName.split('.').pop() || '';
      const nameWithoutExt = originalName.slice(0, -(fileExt.length + 1));

      // List existing files to check for duplicates
      const { data: existingFiles, error: listError } = await supabase.storage
        .from('admin-storage')
        .list(folder);

      if (listError) throw listError;

      // Function to check if a file exists
      const fileExists = (name: string) => existingFiles?.some(f => f.name === name);

      // Generate a unique name if there are duplicates
      let finalName = originalName;
      let counter = 1;
      while (fileExists(finalName)) {
        finalName = `${nameWithoutExt} (${counter}).${fileExt}`;
        counter++;
      }

      const filePath = `${folder}/${finalName}`;

      const { error } = await supabase.storage
        .from('admin-storage')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (error) throw error;

      const { data: { publicUrl } } = supabase.storage.from('admin-storage').getPublicUrl(filePath);

      return {
        path: filePath,
        publicUrl
      };
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  },

  async listFiles(folder: string = 'admin_files') {
    try {
      const { data: files, error } = await supabase.storage
        .from('admin-storage')
        .list(folder, {
          limit: 100,
          offset: 0,
          sortBy: { column: 'created_at', order: 'desc' }
        });

      if (error) {
        console.error('Error listing files:', error);
        return [];
      }

      // Map files to include full path and public URL
      return files.map(file => ({
        name: file.name,
        path: `${folder}/${file.name}`, // Full path for signed URL
        publicUrl: supabase.storage
          .from('admin-storage')
          .getPublicUrl(`${folder}/${file.name}`).data.publicUrl,
        createdAt: file.created_at
      }));
    } catch (error) {
      console.error('Unexpected error listing files:', error);
      return [];
    }
  },

  async deleteFile(path: string) {
    try {
      const { error } = await supabase.storage
        .from('admin-storage')
        .remove([path]);

      if (error) throw error;

      return true;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  }
};
