import { supabase } from '@/lib/supabaseClient';
import type { MeetingProposal, MeetingParticipant, AdminMeetingDetails } from '@/types/meetings';

export const meetingsApi = {
    // Create a new meeting proposal
    createProposal: async (
        proposal: Omit<MeetingProposal, 'id' | 'created_at' | 'updated_at' | 'status'>,
        participantIds: string[]
    ): Promise<AdminMeetingDetails> => {
        console.log('Creating proposal:', proposal);
        console.log('Participant IDs:', participantIds);

        const { data: meetingData, error: meetingError } = await supabase
            .from('meeting_proposals')
            .insert({
                ...proposal,
                status: 'pending'
            })
            .select()
            .single();

        if (meetingError) {
            console.error('Error creating meeting:', meetingError);
            throw meetingError;
        }

        console.log('Created meeting:', meetingData);

        // Add participants
        const participants = participantIds.map(adminId => ({
            meeting_id: meetingData.id,
            admin_id: adminId,
            availability: 'pending'
        }));

        const { data: participantsData, error: participantsError } = await supabase
            .from('meeting_participants')
            .insert(participants)
            .select(`
                admin_id,
                availability,
                response_time,
                notes
            `);

        if (participantsError) {
            console.error('Error adding participants:', participantsError);
            throw participantsError;
        }

        console.log('Added participants:', participantsData);

        // Fetch user details for participants
        const { data: userDetails, error: userError } = await supabase
            .rpc('get_admin_users', { admin_ids: participantIds });

        if (userError) {
            console.error('Error fetching user details:', userError);
            throw userError;
        }

        console.log('User details:', userDetails);

        const userMap = new Map(userDetails.map(user => [
            user.id,
            {
                email: user.email,
                name: user.name || 'Unknown',
                avatar_url: user.avatar_url
            }
        ]));

        return {
            proposal: meetingData,
            participants: participantsData.map(p => {
                const user = userMap.get(p.admin_id);
                return {
                    admin_id: p.admin_id,
                    name: user?.name || 'Unknown',
                    email: user?.email || '',
                    avatar_url: user?.avatar_url,
                    availability: p.availability,
                    response_time: p.response_time,
                    notes: p.notes
                };
            })
        };
    },

    // Get all meeting proposals
    getProposals: async (): Promise<AdminMeetingDetails[]> => {
        console.log('Fetching proposals...');
        // First get all proposals
        const { data: proposals, error: proposalsError } = await supabase
            .from('meeting_proposals')
            .select()
            .order('created_at', { ascending: false });

        if (proposalsError) {
            console.error('Error fetching proposals:', proposalsError);
            throw proposalsError;
        }

        console.log('Fetched proposals:', proposals);

        if (!proposals?.length) {
            console.log('No proposals found');
            return [];
        }

        // Then get all participants for these proposals
        const { data: participants, error: participantsError } = await supabase
            .from('meeting_participants')
            .select(`
                meeting_id,
                admin_id,
                availability,
                response_time,
                notes
            `)
            .in('meeting_id', proposals.map(p => p.id));

        if (participantsError) {
            console.error('Error fetching participants:', participantsError);
            throw participantsError;
        }

        console.log('Fetched participants:', participants);

        // Get unique admin IDs
        const adminIds = [...new Set(participants.map(p => p.admin_id))];

        // Fetch user details for all admins
        const { data: userDetails, error: userError } = await supabase
            .rpc('get_admin_users', { admin_ids: adminIds });

        if (userError) {
            console.error('Error fetching user details:', userError);
            throw userError;
        }

        console.log('Fetched user details:', userDetails);

        // Create maps for efficient lookups
        const userMap = new Map(userDetails.map(user => [
            user.id,
            {
                email: user.email,
                name: user.name || 'Unknown',
                avatar_url: user.avatar_url
            }
        ]));

        const participantsByMeeting = new Map(proposals.map(proposal => [
            proposal.id,
            participants
                .filter(p => p.meeting_id === proposal.id)
                .map(p => {
                    const user = userMap.get(p.admin_id);
                    return {
                        admin_id: p.admin_id,
                        name: user?.name || 'Unknown',
                        email: user?.email || '',
                        avatar_url: user?.avatar_url,
                        availability: p.availability,
                        response_time: p.response_time,
                        notes: p.notes
                    };
                })
        ]));

        return proposals.map(proposal => ({
            proposal,
            participants: participantsByMeeting.get(proposal.id) || []
        }));
    },

    // Update participant availability
    updateAvailability: async (
        meetingId: string,
        adminId: string,
        availability: MeetingParticipant['availability'],
        notes?: string
    ): Promise<void> => {
        console.log('Updating participant availability...');
        const { error } = await supabase
            .from('meeting_participants')
            .update({
                availability,
                notes,
                response_time: new Date().toISOString()
            })
            .match({ meeting_id: meetingId, admin_id: adminId });

        if (error) {
            console.error('Error updating participant availability:', error);
            throw error;
        }

        console.log('Updated participant availability');
    },

    // Get all admin users
    getAdmins: async () => {
        const { data, error } = await supabase
            .rpc('get_admin_users', { admin_ids: [] });

        if (error) throw error;
        return data;
    },

    // Delete a meeting proposal
    deleteProposal: async (meetingId: string): Promise<void> => {
        console.log('Deleting meeting:', meetingId);
        
        // First delete all participants
        const { data: deletedParticipants, error: participantsError } = await supabase
            .from('meeting_participants')
            .delete()
            .eq('meeting_id', meetingId)
            .select();

        if (participantsError) {
            console.error('Error deleting participants:', participantsError);
            throw participantsError;
        }

        console.log('Deleted participants:', deletedParticipants);

        // Then delete the meeting proposal
        const { data: deletedProposal, error: proposalError } = await supabase
            .from('meeting_proposals')
            .delete()
            .eq('id', meetingId)
            .select();

        if (proposalError) {
            console.error('Error deleting proposal:', proposalError);
            throw proposalError;
        }

        console.log('Deleted proposal:', deletedProposal);

        console.log('Successfully deleted meeting:', meetingId);
    },

    // Send meeting invites
    sendMeetingInvites: async (meetingId: string) => {
        try {
            const { data, error } = await supabase.functions.invoke('send-meeting-invites', {
                body: {
                    meeting_id: meetingId,
                    base_url: window.location.origin
                }
            });

            if (error) {
                console.error('Error sending meeting invites:', error);
                throw error;
            }

            return { error: null };
        } catch (error) {
            console.error('Send Meeting Invites Error:', error);
            return { 
                error: error instanceof Error ? error : new Error('Unknown error occurred')
            };
        }
    }
};
