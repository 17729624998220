import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { Calendar as CalendarIcon, Clock, Users, Check, X, Trash2, Mail } from 'lucide-react';
import { meetingsApi } from '@/services/api/meetings';
import type { AdminMeetingDetails, AdminAvailability } from '@/types/meetings';
import { format, parseISO, isAfter } from 'date-fns';
import useAuth from '@/hooks/useAuth';
import { toast } from 'react-hot-toast';

const MeetingScheduler = () => {
    const { user } = useAuth();
    const queryClient = useQueryClient();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [showNewMeetingForm, setShowNewMeetingForm] = useState(false);

    // Fetch meetings
    const { data: meetings = [], isError: meetingsError, refetch } = useQuery({
        queryKey: ['meetings'],
        queryFn: meetingsApi.getProposals,
        staleTime: 0, // Always fetch fresh data
        cacheTime: 0  // Don't cache the data
    });

    // Fetch admins
    const { data: admins = [], isError: adminsError } = useQuery({
        queryKey: ['admins'],
        queryFn: meetingsApi.getAdmins
    });

    // Mutations
    const createMeetingMutation = useMutation({
        mutationFn: (variables: { 
            title: string;
            description: string;
            start_time: string;
            end_time: string;
            participantIds: string[];
        }) => {
            console.log('Creating meeting with variables:', variables);
            return meetingsApi.createProposal({
                title: variables.title,
                description: variables.description,
                start_time: variables.start_time,
                end_time: variables.end_time,
                proposed_by: user!.id
            }, variables.participantIds);
        },
        onSuccess: () => {
            console.log('Meeting created successfully');
            queryClient.invalidateQueries({ queryKey: ['meetings'] });
            setShowNewMeetingForm(false);
            toast.success('Meeting proposed successfully');
        },
        onError: (error) => {
            console.error('Error creating meeting:', error);
            toast.error('Failed to create meeting. Please try again.');
        }
    });

    const updateAvailabilityMutation = useMutation({
        mutationFn: (variables: { 
            meetingId: string;
            availability: AdminAvailability;
            notes?: string;
        }) => meetingsApi.updateAvailability(
            variables.meetingId,
            user!.id,
            variables.availability,
            variables.notes
        ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['meetings'] });
            toast.success('Availability updated');
        },
        onError: () => {
            toast.error('Failed to update availability');
        }
    });

    const deleteMeetingMutation = useMutation({
        mutationFn: (meetingId: string) => meetingsApi.deleteProposal(meetingId),
        onSuccess: async () => {
            console.log('Meeting deleted, refetching...');
            await refetch();  // Explicitly refetch the meetings
            toast.success('Meeting deleted successfully');
        },
        onError: (error) => {
            console.error('Failed to delete meeting:', error);
            toast.error('Failed to delete meeting');
        }
    });

    const handleSendInvites = async (meetingId: string) => {
        try {
            const { error } = await meetingsApi.sendMeetingInvites(meetingId);
            
            if (error) {
                toast.error('Failed to send meeting invites');
                return;
            }

            toast.success('Meeting invites sent successfully');
        } catch (error) {
            console.error('Error sending meeting invites:', error);
            toast.error('Failed to send meeting invites');
        }
    };

    if (meetingsError || adminsError) {
        return (
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
                    <p className="text-red-400">
                        Failed to load meeting data. Please refresh the page and try again.
                    </p>
                </div>
            </div>
        );
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);
        
        const title = formData.get('title') as string;
        const description = formData.get('description') as string;
        const start_time = formData.get('start_time') as string;
        const end_time = formData.get('end_time') as string;
        const participantIds = Array.from(formData.getAll('participants') as string[]);

        console.log('Form data:', {
            title,
            description,
            start_time,
            end_time,
            participantIds
        });

        if (!title || !start_time || !end_time || participantIds.length === 0) {
            toast.error('Please fill in all required fields and select at least one participant');
            return;
        }

        // Ensure start_time and end_time are in ISO format
        const startDate = new Date(start_time);
        const endDate = new Date(end_time);
        
        console.log('Parsed dates:', {
            startDate,
            endDate,
            isStartInFuture: isAfter(startDate, new Date())
        });

        try {
            await createMeetingMutation.mutateAsync({
                title,
                description,
                start_time: startDate.toISOString(),
                end_time: endDate.toISOString(),
                participantIds
            });
        } catch (error) {
            console.error('Form submission error:', error);
        }
    };

    return (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center mb-8">
                <div>
                    <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Meeting Scheduler</h1>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                        Propose and manage admin meetings
                    </p>
                </div>
                <button
                    onClick={() => setShowNewMeetingForm(true)}
                    className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 
                        transition-colors duration-200"
                >
                    Propose Meeting
                </button>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {/* Calendar View */}
                <div className={`${showNewMeetingForm ? 'lg:col-span-2' : 'lg:col-span-3'} bg-white dark:bg-white/5 rounded-xl p-6 shadow-sm dark:shadow-none`}>
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-lg font-medium text-gray-900 dark:text-white">Upcoming Meetings</h2>
                        <div className="flex items-center gap-2">
                            <span className="flex items-center gap-1">
                                <div className="w-3 h-3 rounded-full bg-green-500" />
                                <span className="text-sm text-gray-600 dark:text-gray-400">Available</span>
                            </span>
                            <span className="flex items-center gap-1">
                                <div className="w-3 h-3 rounded-full bg-yellow-500" />
                                <span className="text-sm text-gray-600 dark:text-gray-400">Tentative</span>
                            </span>
                            <span className="flex items-center gap-1">
                                <div className="w-3 h-3 rounded-full bg-red-500" />
                                <span className="text-sm text-gray-600 dark:text-gray-400">Unavailable</span>
                            </span>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {meetings
                            .filter(meeting => {
                                const startTime = parseISO(meeting.proposal.start_time);
                                const now = new Date();
                                const isFuture = isAfter(startTime, now);
                                return isFuture;
                            })
                            .map(meeting => (
                                <motion.div
                                    key={meeting.proposal.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className="bg-gray-50 dark:bg-white/5 rounded-lg p-4 hover:bg-gray-100 dark:hover:bg-white/10 transition-colors duration-200"
                                >
                                    <div className="flex items-start justify-between">
                                        <div>
                                            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                                                {meeting.proposal.title}
                                            </h3>
                                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                                {meeting.proposal.description}
                                            </p>
                                            <div className="flex items-center gap-4 mt-2">
                                                <span className="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
                                                    <CalendarIcon className="w-4 h-4" />
                                                    {format(parseISO(meeting.proposal.start_time), 'MMM d, yyyy')}
                                                </span>
                                                <span className="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
                                                    <Clock className="w-4 h-4" />
                                                    {format(parseISO(meeting.proposal.start_time), 'h:mm a')} - 
                                                    {format(parseISO(meeting.proposal.end_time), 'h:mm a')}
                                                </span>
                                                <span className="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
                                                    <Users className="w-4 h-4" />
                                                    {meeting.participants.length} participants
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            {meeting.proposal.proposed_by === user?.id && (
                                                <div className="flex space-x-2">
                                                    <button
                                                        onClick={() => handleSendInvites(meeting.proposal.id)}
                                                        className="inline-flex items-center px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 
                                                            transition-colors duration-200"
                                                    >
                                                        <Mail className="w-4 h-4 mr-2" />
                                                        Send Invites
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            if (window.confirm('Are you sure you want to delete this meeting?')) {
                                                                deleteMeetingMutation.mutate(meeting.proposal.id);
                                                            }
                                                        }}
                                                        className="inline-flex items-center px-4 py-2 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/30 
                                                            transition-colors duration-200"
                                                    >
                                                        <Trash2 className="w-4 h-4 mr-2" />
                                                        Delete
                                                    </button>
                                                </div>
                                            )}
                                            {meeting.participants.find(p => p.admin_id === user?.id) && (
                                                <div className="flex items-center gap-2">
                                                    <button
                                                        onClick={() => updateAvailabilityMutation.mutate({
                                                            meetingId: meeting.proposal.id,
                                                            availability: 'available'
                                                        })}
                                                        className="p-2 rounded-lg bg-green-500/20 text-green-400 
                                                            hover:bg-green-500/30 transition-colors duration-200"
                                                    >
                                                        <Check className="w-4 h-4" />
                                                    </button>
                                                    <button
                                                        onClick={() => updateAvailabilityMutation.mutate({
                                                            meetingId: meeting.proposal.id,
                                                            availability: 'unavailable'
                                                        })}
                                                        className="p-2 rounded-lg bg-red-500/20 text-red-400 
                                                            hover:bg-red-500/30 transition-colors duration-200"
                                                    >
                                                        <X className="w-4 h-4" />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="flex flex-wrap gap-2">
                                            {meeting.participants.map(participant => (
                                                <div
                                                    key={participant.admin_id}
                                                    className="flex items-center gap-2 bg-white dark:bg-gray-800 rounded-full px-3 py-1"
                                                >
                                                    {participant.avatar_url ? (
                                                        <img
                                                            src={participant.avatar_url}
                                                            alt={participant.name}
                                                            className="w-6 h-6 rounded-full"
                                                        />
                                                    ) : (
                                                        <div className="w-6 h-6 rounded-full bg-primary-500/20 
                                                            flex items-center justify-center text-primary-400 text-xs">
                                                            {participant.name.charAt(0)}
                                                        </div>
                                                    )}
                                                    <span className="text-sm text-gray-600 dark:text-gray-400">{participant.name}</span>
                                                    <div className={`w-2 h-2 rounded-full ${
                                                        participant.availability === 'available' ? 'bg-green-500' :
                                                        participant.availability === 'unavailable' ? 'bg-red-500' :
                                                        'bg-yellow-500'
                                                    }`} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </motion.div>
                            ))}
                    </div>
                </div>

                {/* New Meeting Form */}
                {showNewMeetingForm && (
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        className="lg:col-span-1 bg-white dark:bg-white/5 rounded-xl p-6 shadow-sm dark:shadow-none"
                    >
                        <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Propose New Meeting</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    required
                                    className="mt-1 block w-full rounded-md bg-white dark:bg-gray-800 border border-gray-300 
                                        dark:border-white/10 focus:border-primary-500 focus:ring-primary-500 
                                        text-gray-900 dark:text-white [&>option]:dark:bg-gray-800 [&>option]:dark:text-white"
                                />
                            </div>

                            <div>
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                    Description
                                </label>
                                <textarea
                                    name="description"
                                    id="description"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md bg-white dark:bg-gray-800 border border-gray-300 
                                        dark:border-white/10 focus:border-primary-500 focus:ring-primary-500 
                                        text-gray-900 dark:text-white"
                                />
                            </div>

                            <div>
                                <label htmlFor="start_time" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                    Start Time
                                </label>
                                <input
                                    type="datetime-local"
                                    name="start_time"
                                    id="start_time"
                                    required
                                    min={new Date().toISOString().slice(0, 16)}
                                    className="mt-1 block w-full rounded-md bg-white dark:bg-gray-800 border border-gray-300 
                                        dark:border-white/10 focus:border-primary-500 focus:ring-primary-500 
                                        text-gray-900 dark:text-white"
                                />
                            </div>

                            <div>
                                <label htmlFor="end_time" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                    End Time
                                </label>
                                <input
                                    type="datetime-local"
                                    name="end_time"
                                    id="end_time"
                                    required
                                    min={new Date().toISOString().slice(0, 16)}
                                    className="mt-1 block w-full rounded-md bg-white dark:bg-gray-800 border border-gray-300 
                                        dark:border-white/10 focus:border-primary-500 focus:ring-primary-500 
                                        text-gray-900 dark:text-white"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                    Participants
                                </label>
                                <div className="mt-2 space-y-2">
                                    {admins.map(admin => (
                                        <label
                                            key={admin.id}
                                            className="flex items-center gap-2"
                                        >
                                            <input
                                                type="checkbox"
                                                name="participants"
                                                value={admin.id}
                                                className="rounded bg-white dark:bg-gray-800 border-gray-300 dark:border-white/10 
                                                    text-primary-500 focus:ring-primary-500"
                                            />
                                            <span className="text-sm text-gray-600 dark:text-gray-400">{admin.name}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <div className="flex justify-end gap-4">
                                <button
                                    type="button"
                                    onClick={() => setShowNewMeetingForm(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-400 hover:text-white 
                                        transition-colors duration-200"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={createMeetingMutation.isPending}
                                    className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 
                                        transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    {createMeetingMutation.isPending ? 'Creating...' : 'Propose Meeting'}
                                </button>
                            </div>
                        </form>
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default MeetingScheduler;
