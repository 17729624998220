import { type FC } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

interface DocumentationDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const DocumentationDialog: FC<DocumentationDialogProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/70"
          />

          {/* Dialog */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative max-h-[90vh] w-full max-w-4xl overflow-y-auto rounded-2xl bg-gray-900 p-6 shadow-xl"
          >
            <button
              onClick={onClose}
              className="absolute right-4 top-4 rounded-lg p-2 text-gray-400 hover:bg-gray-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>

            <div className="mt-2">
              <h1 className="text-3xl font-bold text-white mb-8">Scholar Tree Documentation</h1>
              
              <div className="prose prose-invert max-w-none">
                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Getting Started</h2>
                
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Parent Account Setup</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Download Scholar Tree from the App Store</li>
                  <li>Create an individual or parent account using your email</li>
                  <li>Enable Face ID/Touch ID (optional) for secure access</li>
                  <li>Add your children's accounts</li>
                  <li>Configure parental controls and preferences</li>
                </ul>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Student Account Setup</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Parent creates student account</li>
                  <li>Set education level and subject focus</li>
                  <li>Configure guided study preferences</li>
                  <li>Upload study materials (optional)</li>
                  <li>Begin using the Virtual Tutor</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Main Features</h2>
                
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Virtual Tutor</h3>
                <h4 className="text-lg font-semibold text-white mt-4 mb-2">Text Conversations</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Open the Virtual Tutor section</li>
                  <li>Type your question or select from suggested prompts</li>
                  <li>Wait for AI response</li>
                  <li>Continue conversation as needed</li>
                  <li>Messages are retained for 24 hours</li>
                </ul>

                <h4 className="text-lg font-semibold text-white mt-4 mb-2">Image Upload</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Tap camera or photo library icon</li>
                  <li>Take photo or select existing image</li>
                  <li>Add text description (optional)</li>
                  <li>Submit for AI analysis</li>
                  <li>Receive detailed explanation</li>
                </ul>

                <h4 className="text-lg font-semibold text-white mt-4 mb-2">Voice Interaction</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Select audio transcription feature</li>
                  <li>Record your question or lecture</li>
                  <li>Review transcription</li>
                  <li>Generate study materials from audio</li>
                  <li>Save or edit generated content</li>
                </ul>

                <h4 className="text-lg font-semibold text-white mt-4 mb-2">Drawing Tools</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Access drawing interface</li>
                  <li>Draw equations or diagrams</li>
                  <li>Submit for analysis</li>
                  <li>Receive step-by-step solutions</li>
                  <li>Save solutions for later reference</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Parent Dashboard</h2>
                
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Activity Monitoring</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Select "Activity" from dashboard</li>
                  <li>View calendar of student usage</li>
                  <li>Check session durations</li>
                  <li>Review conversation history</li>
                  <li>Monitor study progress</li>
                </ul>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Content Controls</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Access "Controls" section</li>
                  <li>Set education level</li>
                  <li>Configure tutor personality</li>
                  <li>Define restricted content</li>
                  <li>Adjust problem-solving approach</li>
                </ul>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Alert System</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Open "Alerts" section</li>
                  <li>Enable desired alert categories</li>
                  <li>Add custom keywords</li>
                  <li>Set notification preferences</li>
                  <li>Review alert history</li>
                </ul>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Study Materials</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Select "Materials" section</li>
                  <li>View uploaded content</li>
                  <li>Check generated study guides</li>
                  <li>Review student notes</li>
                  <li>Access learning resources</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Student Features</h2>
                
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Guided Study</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Open Virtual Tutor</li>
                  <li>Review suggested topics</li>
                  <li>Select area of focus</li>
                  <li>Engage with AI tutor</li>
                  <li>Generate study materials</li>
                </ul>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Study Tools</h3>
                <p className="text-gray-300 mb-2">Generate various study aids:</p>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Study guides</li>
                  <li>Flashcards</li>
                  <li>Mind maps</li>
                  <li>Practice questions</li>
                  <li>Key concept lists</li>
                  <li>Summaries</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Settings and Preferences</h2>
                
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Appearance</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Access menu</li>
                  <li>Toggle Light/Dark mode</li>
                  <li>Adjust text size</li>
                  <li>Set color preferences</li>
                  <li>Configure accessibility options</li>
                </ul>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Security</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Enable Face ID/Touch ID</li>
                  <li>Set access restrictions</li>
                  <li>Configure privacy settings</li>
                  <li>Manage account permissions</li>
                  <li>Set up backup options</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Tips for Best Results</h2>
                
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">For Students</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Be specific with questions</li>
                  <li>Upload clear images</li>
                  <li>Use guided study mode for focused learning</li>
                  <li>Save important conversations</li>
                  <li>Review generated materials regularly</li>
                </ul>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">For Parents</h3>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Regular review of activity logs</li>
                  <li>Update content restrictions as needed</li>
                  <li>Monitor alert notifications</li>
                  <li>Check study progress</li>
                  <li>Maintain communication settings</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Troubleshooting</h2>
                
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Common Issues</h3>
                
                <h4 className="text-lg font-semibold text-white mt-4 mb-2">App not responding</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Force close and restart</li>
                  <li>Check internet connection</li>
                  <li>Update app if needed</li>
                </ul>

                <h4 className="text-lg font-semibold text-white mt-4 mb-2">Image upload fails</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Check image size</li>
                  <li>Ensure good lighting</li>
                  <li>Try different format</li>
                </ul>

                <h4 className="text-lg font-semibold text-white mt-4 mb-2">Voice recognition issues</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Speak clearly</li>
                  <li>Reduce background noise</li>
                  <li>Check microphone permissions</li>
                </ul>

                <h4 className="text-lg font-semibold text-white mt-4 mb-2">Content access problems</h4>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Verify parent controls</li>
                  <li>Check account status</li>
                  <li>Confirm permissions</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Support</h2>
                <p className="text-gray-300 mb-2">Contact support@scholartree.app for:</p>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li>Technical issues</li>
                  <li>Account problems</li>
                  <li>Feature requests</li>
                  <li>General questions</li>
                  <li>Privacy concerns</li>
                </ul>

                <p className="text-gray-300 mt-8">Keep app updated for best performance and latest features.</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default DocumentationDialog;
