import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface PaginationProps {
    currentPage: number;
    totalPosts: number;
    postsPerPage: number;
    onPageChange: (page: number) => void;
    className?: string;
}

const Pagination: React.FC<PaginationProps> = ({ 
    currentPage, 
    totalPosts, 
    postsPerPage,
    onPageChange,
    className = ""
}) => {
    const totalPages = Math.ceil(totalPosts / postsPerPage);

    if (totalPosts <= postsPerPage) return null;

    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className={`flex justify-center ${className}`}
        >
            <nav className="flex items-center gap-6" aria-label="Blog pagination">
                <button
                    onClick={() => onPageChange(Math.max(1, currentPage - 1))}
                    disabled={currentPage === 1}
                    className="flex items-center gap-2 px-6 py-3 rounded-xl bg-white/5 hover:bg-white/10 
                        disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200
                        hover:shadow-lg hover:shadow-primary-500/10"
                    aria-label="Previous page"
                >
                    <ChevronLeft className="w-5 h-5" />
                    <span>Previous</span>
                </button>
                <div className="text-white">
                    Page {currentPage} of {totalPages}
                </div>
                <button
                    onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
                    disabled={currentPage === totalPages}
                    className="flex items-center gap-2 px-6 py-3 rounded-xl bg-white/5 hover:bg-white/10 
                        disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200
                        hover:shadow-lg hover:shadow-primary-500/10"
                    aria-label="Next page"
                >
                    <span>Next</span>
                    <ChevronRight className="w-5 h-5" />
                </button>
            </nav>
        </motion.div>
    );
};

export default Pagination;
