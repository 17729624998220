import { Helmet } from 'react-helmet-async';

export default function MainSEO() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "ScholarTree",
    "description": "AI-powered tutoring platform providing personalized learning support and comprehensive study tools",
    "url": "https://scholartree.app",
    "sameAs": [
      // Add your social media profiles here
    ],
    "offers": {
      "@type": "Offer",
      "category": "Educational Service",
      "description": "AI-powered tutoring and learning support"
    },
    "serviceType": "Education Technology",
    "audience": {
      "@type": "EducationalAudience",
      "educationalRole": "student"
    },
    "potentialAction": {
      "@type": "LearnAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://scholartree.app",
        "actionPlatform": [
          "https://schema.org/DesktopWebPlatform",
          "https://schema.org/IOSPlatform",
          "https://schema.org/AndroidPlatform"
        ]
      }
    }
  };

  return (
    <Helmet>
      <title>ScholarTree - Your Personal AI Learning Companion</title>
      <meta name="description" content="Transform your learning experience with our AI-powered tutoring platform. Get personalized support, instant feedback, and comprehensive study tools - all in one place." />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="ScholarTree - Your Personal AI Learning Companion" />
      <meta property="og:description" content="Transform your learning experience with our AI-powered tutoring platform. Get personalized support, instant feedback, and comprehensive study tools - all in one place." />
      <meta property="og:url" content="https://scholartree.app" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="ScholarTree - Your Personal AI Learning Companion" />
      <meta name="twitter:description" content="Transform your learning experience with our AI-powered tutoring platform. Get personalized support, instant feedback, and comprehensive study tools - all in one place." />
      
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
}
