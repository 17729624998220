import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { Clock, AlertTriangle, CheckCircle, XCircle, Play, Pause, Pencil } from 'lucide-react';
import { format, parseISO, isBefore } from 'date-fns';
import { tasksApi } from '@/services/api/tasks';
import { meetingsApi } from '@/services/api/meetings';
import type { TaskWithDetails, TaskStatus, TaskPriority } from '@/types/tasks';
import type { AdminUser } from '@/types/meetings';
import useAuth from '@/hooks/useAuth';
import { toast } from 'react-hot-toast';

const priorityColors = {
    frog: 'bg-green-500',
    high: 'bg-red-500',
    medium: 'bg-yellow-500',
    low: 'bg-blue-500'
};

const statusIcons = {
    not_started: <AlertTriangle className="w-5 h-5 text-yellow-500" />,
    in_progress: <Play className="w-5 h-5 text-blue-500" />,
    blocked: <XCircle className="w-5 h-5 text-red-500" />,
    completed: <CheckCircle className="w-5 h-5 text-green-500" />
};

export default function TaskManager() {
    const { user } = useAuth();
    const queryClient = useQueryClient();
    const [showNewTaskForm, setShowNewTaskForm] = useState(false);
    const [activeTimeLog, setActiveTimeLog] = useState<string | null>(null);
    const [editingTask, setEditingTask] = useState<TaskWithDetails | null>(null);

    // Fetch tasks
    const { data: tasks = [], isError: tasksError } = useQuery({
        queryKey: ['tasks'],
        queryFn: tasksApi.getTasks
    });

    // Fetch admins
    const { data: admins = [], isError: adminsError } = useQuery<AdminUser[]>({
        queryKey: ['admins'],
        queryFn: meetingsApi.getAdmins
    });

    // Mutations
    const createTaskMutation = useMutation({
        mutationFn: tasksApi.createTask,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            setShowNewTaskForm(false);
            toast.success('Task created successfully');
        },
        onError: () => toast.error('Failed to create task')
    });

    const updateTaskMutation = useMutation({
        mutationFn: ({ taskId, updates }: { taskId: string; updates: Parameters<typeof tasksApi.updateTask>[1] }) =>
            tasksApi.updateTask(taskId, updates),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            setEditingTask(null);
            toast.success('Task updated successfully');
        },
        onError: () => toast.error('Failed to update task')
    });

    const updateStatusMutation = useMutation({
        mutationFn: ({ taskId, status }: { taskId: string; status: TaskStatus }) =>
            tasksApi.updateTaskStatus(
                taskId,
                status,
                status === 'completed' ? new Date().toISOString() : undefined
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Task status updated');
        },
        onError: () => toast.error('Failed to update task status')
    });

    const startTimeMutation = useMutation({
        mutationFn: ({ taskId }: { taskId: string }) =>
            tasksApi.startTimeTracking(taskId, user!.id),
        onSuccess: (data) => {
            setActiveTimeLog(data.id);
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Time tracking started');
        },
        onError: () => toast.error('Failed to start time tracking')
    });

    const stopTimeMutation = useMutation({
        mutationFn: tasksApi.stopTimeTracking,
        onSuccess: () => {
            setActiveTimeLog(null);
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Time tracking stopped');
        },
        onError: () => toast.error('Failed to stop time tracking')
    });

    // Sort tasks by priority and completion status
    const sortedTasks = [...tasks].sort((a, b) => {
        if (a.status === 'completed' && b.status !== 'completed') return 1;
        if (a.status !== 'completed' && b.status === 'completed') return -1;
        
        const priorityOrder = { frog: 3, high: 2, medium: 1, low: 0 };
        const priorityDiff = priorityOrder[b.priority] - priorityOrder[a.priority];
        if (priorityDiff !== 0) return priorityDiff;

        if (a.due_date && b.due_date) {
            return isBefore(parseISO(a.due_date), parseISO(b.due_date)) ? -1 : 1;
        }
        return 0;
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        const title = formData.get('title') as string;
        const description = formData.get('description') as string;
        const priority = formData.get('priority') as TaskPriority;
        const due_date = formData.get('due_date') as string;
        const assigned_to = formData.get('assigned_to') as string;
        const estimated_hours = parseFloat(formData.get('estimated_hours') as string);
        const tags = (formData.get('tags') as string).split(',').map(t => t.trim()).filter(Boolean);

        if (!title || !priority) {
            toast.error('Please fill in all required fields');
            return;
        }

        const taskData = {
            title,
            description,
            priority,
            status: 'not_started' as const,
            due_date: due_date || undefined,
            assigned_to: assigned_to || undefined,
            assigned_by: user!.id,
            tags,
            estimated_hours: isNaN(estimated_hours) ? undefined : estimated_hours,
            blocked_by: []
        };

        try {
            if (editingTask) {
                await updateTaskMutation.mutateAsync({
                    taskId: editingTask.id,
                    updates: taskData
                });
            } else {
                await createTaskMutation.mutateAsync(taskData);
            }
        } catch (error) {
            console.error('Form submission error:', error);
        }
    };

    if (tasksError || adminsError) {
        return (
            <div className="p-4 text-red-500">
                Error loading data. Please try again later.
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center mb-8">
                <div>
                    <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Task Manager</h1>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                        Eat That Frog! 🐸 Start with your biggest, most important task.
                    </p>
                </div>
                <button
                    onClick={() => {
                        setEditingTask(null);
                        setShowNewTaskForm(true);
                    }}
                    className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 
                        transition-colors duration-200"
                >
                    Create Task
                </button>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {/* Task List */}
                <div className={`${showNewTaskForm ? 'lg:col-span-2' : 'lg:col-span-3'} space-y-4`}>
                    {sortedTasks.map(task => (
                        <motion.div
                            key={task.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className={`bg-white dark:bg-white/5 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-white/10 
                                transition-colors duration-200 shadow-sm ${task.status === 'completed' ? 'opacity-60' : ''}`}
                        >
                            <div className="flex items-start justify-between">
                                <div className="flex-1">
                                    <div className="flex items-center gap-2">
                                        <div className={`w-3 h-3 rounded-full ${priorityColors[task.priority]}`} />
                                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                                            {task.title}
                                        </h3>
                                        {statusIcons[task.status]}
                                    </div>
                                    {task.description && (
                                        <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                            {task.description}
                                        </p>
                                    )}
                                    <div className="flex flex-wrap items-center gap-4 mt-2">
                                        {task.due_date && (
                                            <span className="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
                                                <Clock className="w-4 h-4" />
                                                {format(parseISO(task.due_date), 'MMM d, yyyy')}
                                            </span>
                                        )}
                                        {task.assigned_to && (
                                            <span className="flex items-center gap-2">
                                                <img
                                                    src={admins.find(a => a.id === task.assigned_to)?.avatar_url || '/default-avatar.png'}
                                                    alt={admins.find(a => a.id === task.assigned_to)?.name || 'Unknown'}
                                                    className="w-6 h-6 rounded-full"
                                                />
                                                <span className="text-sm text-gray-600 dark:text-gray-400">
                                                    {admins.find(a => a.id === task.assigned_to)?.name || 'Unknown'}
                                                </span>
                                            </span>
                                        )}
                                        {task.tags.map(tag => (
                                            <span
                                                key={tag}
                                                className="px-2 py-1 text-xs rounded-full bg-gray-100 dark:bg-gray-700 
                                                    text-gray-700 dark:text-gray-300"
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    {task.status !== 'completed' && (
                                        <>
                                            <button
                                                onClick={() => {
                                                    setEditingTask(task);
                                                    setShowNewTaskForm(true);
                                                }}
                                                className="p-2 rounded-lg bg-blue-50 dark:bg-blue-500/20 text-blue-600 
                                                    dark:text-blue-400 hover:bg-blue-100 dark:hover:bg-blue-500/30 
                                                    transition-colors duration-200"
                                            >
                                                <Pencil className="w-5 h-5" />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    const timeLog = task.time_logs.find(
                                                        log => log.id === activeTimeLog
                                                    );
                                                    if (timeLog) {
                                                        stopTimeMutation.mutate(timeLog.id);
                                                    } else {
                                                        startTimeMutation.mutate({
                                                            taskId: task.id
                                                        });
                                                    }
                                                }}
                                                className="p-2 rounded-lg bg-primary-50 dark:bg-primary-500/20 
                                                    text-primary-600 dark:text-primary-400 hover:bg-primary-100 
                                                    dark:hover:bg-primary-500/30 transition-colors duration-200"
                                            >
                                                {activeTimeLog === task.id ? (
                                                    <Pause className="w-5 h-5" />
                                                ) : (
                                                    <Play className="w-5 h-5" />
                                                )}
                                            </button>
                                            <button
                                                onClick={() => updateStatusMutation.mutate({
                                                    taskId: task.id,
                                                    status: 'completed'
                                                })}
                                                className="p-2 rounded-lg bg-green-50 dark:bg-green-500/20 
                                                    text-green-600 dark:text-green-400 hover:bg-green-100 
                                                    dark:hover:bg-green-500/30 transition-colors duration-200"
                                            >
                                                <CheckCircle className="w-5 h-5" />
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* Task Form */}
                {showNewTaskForm && (
                    <div className="bg-white dark:bg-white/5 rounded-lg p-6 shadow-sm">
                        <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                            {editingTask ? 'Edit Task' : 'Create New Task'}
                        </h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-1">
                                    Title *
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    required
                                    defaultValue={editingTask?.title}
                                    className="w-full px-3 py-2 bg-white dark:bg-white/5 border border-gray-300 
                                        dark:border-white/10 rounded-lg text-gray-900 dark:text-white 
                                        placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 
                                        focus:border-transparent"
                                    placeholder="Enter task title"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-1">
                                    Description
                                </label>
                                <textarea
                                    name="description"
                                    rows={3}
                                    defaultValue={editingTask?.description}
                                    className="w-full px-3 py-2 bg-white dark:bg-white/5 border border-gray-300 
                                        dark:border-white/10 rounded-lg text-gray-900 dark:text-white 
                                        placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 
                                        focus:border-transparent"
                                    placeholder="Enter task description"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-1">
                                    Priority *
                                </label>
                                <select
                                    name="priority"
                                    required
                                    defaultValue={editingTask?.priority}
                                    className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 
                                        dark:border-white/10 rounded-lg text-gray-900 dark:text-white 
                                        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent
                                        [&>option]:dark:bg-gray-800 [&>option]:dark:text-white"
                                >
                                    <option value="frog">🐸 Frog (Do it first!)</option>
                                    <option value="high">High Priority</option>
                                    <option value="medium">Medium Priority</option>
                                    <option value="low">Low Priority</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-1">
                                    Assign To
                                </label>
                                <select
                                    name="assigned_to"
                                    defaultValue={editingTask?.assigned_to}
                                    className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 
                                        dark:border-white/10 rounded-lg text-gray-900 dark:text-white 
                                        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent
                                        [&>option]:dark:bg-gray-800 [&>option]:dark:text-white"
                                >
                                    <option value="">Unassigned</option>
                                    {admins.map(admin => (
                                        <option key={admin.id} value={admin.id}>
                                            {admin.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-1">
                                    Due Date
                                </label>
                                <input
                                    type="datetime-local"
                                    name="due_date"
                                    defaultValue={editingTask?.due_date?.split('.')[0]}
                                    className="w-full px-3 py-2 bg-white dark:bg-white/5 border border-gray-300 
                                        dark:border-white/10 rounded-lg text-gray-900 dark:text-white 
                                        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-1">
                                    Estimated Hours
                                </label>
                                <input
                                    type="number"
                                    name="estimated_hours"
                                    step="0.5"
                                    min="0"
                                    defaultValue={editingTask?.estimated_hours}
                                    className="w-full px-3 py-2 bg-white dark:bg-white/5 border border-gray-300 
                                        dark:border-white/10 rounded-lg text-gray-900 dark:text-white 
                                        placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 
                                        focus:border-transparent"
                                    placeholder="Enter estimated hours"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mb-1">
                                    Tags
                                </label>
                                <input
                                    type="text"
                                    name="tags"
                                    defaultValue={editingTask?.tags.join(', ')}
                                    className="w-full px-3 py-2 bg-white dark:bg-white/5 border border-gray-300 
                                        dark:border-white/10 rounded-lg text-gray-900 dark:text-white 
                                        placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 
                                        focus:border-transparent"
                                    placeholder="Enter tags (comma separated)"
                                />
                            </div>
                            <div className="flex justify-end gap-4">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setEditingTask(null);
                                        setShowNewTaskForm(false);
                                    }}
                                    className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 
                                        dark:hover:text-white transition-colors duration-200"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 
                                        transition-colors duration-200"
                                >
                                    {editingTask ? 'Update Task' : 'Create Task'}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}
