import { 
  Mail, 
  Settings, 
  BarChart, 
  Home, 
  BookOpen, 
  Users, 
  DollarSign, 
  LineChart, 
  Mails, 
  Folder,
  Calendar,
  CheckSquare
} from 'lucide-react';

export const adminNavigation = [
    {
        name: 'Dashboard',
        href: '/admin',
        icon: Home,
    },
    {
        name: 'Tasks',
        href: '/admin/tasks',
        icon: CheckSquare,
    },
    {
        name: 'Blog',
        href: '/admin/blog',
        icon: BookOpen,
    },
    {
        name: 'Newsletter',
        href: '/admin/newsletter',
        icon: Mail,
    },
    {
        name: 'Email Templates',
        href: '/admin/email-templates',
        icon: Mails,
    },
    {
        name: 'Admin Storage',
        href: '/admin/storage',
        icon: Folder,
    },
    {
        name: 'Meeting Scheduler',
        href: '/admin/meetings',
        icon: Calendar,
    },
    {
        name: 'AI Settings',
        href: '/admin/ai-settings',
        icon: Settings,
    },
    {
        name: 'Chat Analytics',
        href: '/admin/analytics',
        icon: BarChart,
    },
    {
        name: 'Affiliate Management',
        items: [
            {
                name: 'Affiliates',
                href: '/admin/affiliates/management',
                icon: Users,
            },
            {
                name: 'Analytics',
                href: '/admin/affiliates/analytics',
                icon: LineChart,
            },
            {
                name: 'Payouts',
                href: '/admin/affiliates/payouts',
                icon: DollarSign,
            }
        ]
    }
];
