import { supabase } from '@/lib/supabaseClient';
import type { Task, TaskComment, TaskAttachment, TaskTimeLog, TaskWithDetails, TaskStatus, TaskPriority } from '@/types/tasks';

export const tasksApi = {
    // Get all tasks with details
    getTasks: async (): Promise<TaskWithDetails[]> => {
        // Fetch tasks
        const { data: tasks, error: tasksError } = await supabase
            .from('tasks')
            .select('*')
            .order('priority', { ascending: false })
            .order('due_date', { ascending: true });

        if (tasksError) throw tasksError;

        // Get unique assigned user IDs
        const userIds = [...new Set(tasks.map(t => t.assigned_to).filter(Boolean))];

        // Fetch user details
        const { data: users, error: usersError } = await supabase
            .rpc('get_admin_users', { admin_ids: userIds });

        if (usersError) throw usersError;

        const userMap = new Map(users.map(user => [
            user.id,
            {
                id: user.id,
                name: user.name || 'Unknown',
                email: user.email,
                avatar_url: user.avatar_url
            }
        ]));

        // Fetch related data for all tasks
        const { data: comments } = await supabase
            .from('task_comments')
            .select('*')
            .in('task_id', tasks.map(t => t.id));

        const { data: attachments } = await supabase
            .from('task_attachments')
            .select('*')
            .in('task_id', tasks.map(t => t.id));

        const { data: timeLogs } = await supabase
            .from('task_time_logs')
            .select('*')
            .in('task_id', tasks.map(t => t.id));

        const { data: watchers } = await supabase
            .from('task_watchers')
            .select('*')
            .in('task_id', tasks.map(t => t.id));

        // Create maps for efficient lookups
        const commentsByTask = new Map(tasks.map(task => [task.id, []]));
        comments?.forEach(comment => {
            const taskComments = commentsByTask.get(comment.task_id) || [];
            taskComments.push(comment);
        });

        const attachmentsByTask = new Map(tasks.map(task => [task.id, []]));
        attachments?.forEach(attachment => {
            const taskAttachments = attachmentsByTask.get(attachment.task_id) || [];
            taskAttachments.push(attachment);
        });

        const timeLogsByTask = new Map(tasks.map(task => [task.id, []]));
        timeLogs?.forEach(log => {
            const taskLogs = timeLogsByTask.get(log.task_id) || [];
            taskLogs.push(log);
        });

        const watchersByTask = new Map(tasks.map(task => [task.id, []]));
        watchers?.forEach(watcher => {
            const taskWatchers = watchersByTask.get(watcher.task_id) || [];
            taskWatchers.push(watcher.user_id);
        });

        // Combine all data
        return tasks.map(task => ({
            ...task,
            comments: commentsByTask.get(task.id) || [],
            attachments: attachmentsByTask.get(task.id) || [],
            time_logs: timeLogsByTask.get(task.id) || [],
            watchers: watchersByTask.get(task.id) || [],
            assignee: task.assigned_to ? userMap.get(task.assigned_to) : undefined
        }));
    },

    // Create a new task
    createTask: async (
        task: Omit<Task, 'id' | 'created_at' | 'updated_at'> & { watchers?: string[] }
    ): Promise<TaskWithDetails> => {
        const { data: newTask, error: taskError } = await supabase
            .from('tasks')
            .insert(task)
            .select()
            .single();

        if (taskError) throw taskError;

        // Add watchers if specified
        if (task.watchers?.length) {
            const watcherRecords = task.watchers.map(userId => ({
                task_id: newTask.id,
                user_id: userId
            }));

            const { error: watchersError } = await supabase
                .from('task_watchers')
                .insert(watcherRecords);

            if (watchersError) throw watchersError;
        }

        // Fetch the task with all details
        const tasks = await tasksApi.getTasks();
        const taskWithDetails = tasks.find(t => t.id === newTask.id);
        if (!taskWithDetails) throw new Error('Failed to fetch created task');

        return taskWithDetails;
    },

    // Update task status
    updateTaskStatus: async (
        taskId: string,
        status: TaskStatus,
        completedAt?: string
    ): Promise<void> => {
        const { error } = await supabase
            .from('tasks')
            .update({ 
                status,
                completed_at: completedAt,
                updated_at: new Date().toISOString()
            })
            .eq('id', taskId);

        if (error) throw error;
    },

    // Update task
    updateTask: async (
        taskId: string,
        updates: Partial<Omit<Task, 'id' | 'created_at' | 'updated_at'>> & { watchers?: string[] }
    ): Promise<TaskWithDetails> => {
        // Update task
        const { data: updatedTask, error: taskError } = await supabase
            .from('tasks')
            .update({
                ...updates,
                updated_at: new Date().toISOString()
            })
            .eq('id', taskId)
            .select()
            .single();

        if (taskError) throw taskError;

        // Update watchers if specified
        if (updates.watchers) {
            // First delete existing watchers
            const { error: deleteError } = await supabase
                .from('task_watchers')
                .delete()
                .eq('task_id', taskId);

            if (deleteError) throw deleteError;

            // Then add new watchers
            if (updates.watchers.length > 0) {
                const watcherRecords = updates.watchers.map(userId => ({
                    task_id: taskId,
                    user_id: userId
                }));

                const { error: watchersError } = await supabase
                    .from('task_watchers')
                    .insert(watcherRecords);

                if (watchersError) throw watchersError;
            }
        }

        // Fetch the task with all details
        const tasks = await tasksApi.getTasks();
        const taskWithDetails = tasks.find(t => t.id === updatedTask.id);
        if (!taskWithDetails) throw new Error('Failed to fetch updated task');

        return taskWithDetails;
    },

    // Add comment to task
    addComment: async (taskId: string, userId: string, content: string): Promise<TaskComment> => {
        const { data, error } = await supabase
            .from('task_comments')
            .insert({
                task_id: taskId,
                user_id: userId,
                content
            })
            .select()
            .single();

        if (error) throw error;
        return data;
    },

    // Start time tracking
    startTimeTracking: async (taskId: string, userId: string, description?: string): Promise<TaskTimeLog> => {
        const { data, error } = await supabase
            .from('task_time_logs')
            .insert({
                task_id: taskId,
                user_id: userId,
                start_time: new Date().toISOString(),
                description
            })
            .select()
            .single();

        if (error) throw error;
        return data;
    },

    // Stop time tracking
    stopTimeTracking: async (timeLogId: string): Promise<void> => {
        const { error } = await supabase
            .from('task_time_logs')
            .update({ end_time: new Date().toISOString() })
            .eq('id', timeLogId);

        if (error) throw error;
    },

    // Update task priority
    updateTaskPriority: async (taskId: string, priority: TaskPriority): Promise<void> => {
        const { error } = await supabase
            .from('tasks')
            .update({ 
                priority,
                updated_at: new Date().toISOString()
            })
            .eq('id', taskId);

        if (error) throw error;
    }
};
