import { type FC, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { 
  Download, 
  Loader2, 
  Mail, 
  Trash2, 
  Edit2, 
  UserCheck, 
  UserX, 
  X 
} from 'lucide-react';
import { affiliateApi } from '@/services/api/affiliate';
import { AffiliateApplication } from '@/types/affiliate';

const AffiliateManagement: FC = () => {
  const queryClient = useQueryClient();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [selectedAffiliate, setSelectedAffiliate] = useState<AffiliateApplication | null>(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const { data: applications, isLoading, error } = useQuery<AffiliateApplication[]>({
    queryKey: ['affiliate-applications'],
    queryFn: () => affiliateApi.getApplications(),
  });

  const bulkApproveMutation = useMutation({
    mutationFn: (ids: string[]) => Promise.all(ids.map(id => affiliateApi.approveApplication(id))),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success(`${selectedIds.length} applications approved`);
      setSelectedIds([]);
    },
    onError: (error) => {
      console.error('Error approving applications:', error);
      toast.error('Failed to approve applications');
    },
  });

  const bulkRejectMutation = useMutation({
    mutationFn: (ids: string[]) => Promise.all(ids.map(id => affiliateApi.rejectApplication(id))),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success(`${selectedIds.length} applications rejected`);
      setSelectedIds([]);
    },
    onError: (error) => {
      console.error('Error rejecting applications:', error);
      toast.error('Failed to reject applications');
    },
  });

  const toggleStatusMutation = useMutation({
    mutationFn: ({ id, status }: { id: string; status: 'active' | 'inactive' }) => 
      affiliateApi.updateApplicationStatus(id, status),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success('Affiliate status updated successfully');
    },
    onError: (error) => {
      console.error('Error updating affiliate status:', error);
      toast.error('Failed to update affiliate status');
    },
  });

  const deleteAffiliateMutation = useMutation({
    mutationFn: async (affiliateId: string) => {
      await affiliateApi.deleteAffiliate(affiliateId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success('Affiliate deleted successfully');
    },
    onError: (error) => {
      console.error('Error deleting affiliate:', error);
      toast.error('Failed to delete affiliate: ' + (error instanceof Error ? error.message : 'Unknown error'));
    },
  });

  const resendWelcomeEmailMutation = useMutation({
    mutationFn: async (affiliateId: string) => {
      await affiliateApi.resendWelcomeEmail(affiliateId);
    },
    onSuccess: () => {
      toast.success('Welcome email sent successfully');
    },
    onError: (error) => {
      console.error('Error sending welcome email:', error);
      toast.error('Failed to send welcome email');
    },
  });

  const handleExport = () => {
    const filteredApps = getFilteredApplications();
    const csvContent = [
      ['First Name', 'Last Name', 'Email', 'Website', 'Preferred Tier', 'Status', 'Applied At'].join(','),
      ...filteredApps.map(app => [
        app.first_name,
        app.last_name,
        app.email,
        app.website || 'N/A',
        app.preferred_tier,
        app.status,
        format(new Date(app.created_at), 'yyyy-MM-dd HH:mm:ss')
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `affiliate-applications-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const getFilteredApplications = () => {
    if (!applications) return [];
    
    return applications.filter(app => {
      const matchesSearch = searchTerm === '' || 
        `${app.first_name} ${app.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        app.email.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = !statusFilter || app.status === statusFilter;
      
      return matchesSearch && matchesStatus;
    });
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const filteredIds = getFilteredApplications()
        .filter(app => app.status === 'pending')
        .map(app => app.id);
      setSelectedIds(filteredIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleViewDetails = (affiliate: AffiliateApplication) => {
    setSelectedAffiliate(affiliate);
    setShowDetailsDialog(true);
  };

  const closeDetailsDialog = () => {
    setSelectedAffiliate(null);
    setShowDetailsDialog(false);
  };

  const handleToggleStatus = (affiliate: AffiliateApplication) => {
    const newStatus = affiliate.status === 'active' ? 'inactive' : 'active';
    toggleStatusMutation.mutate({ 
      id: affiliate.id, 
      status: newStatus 
    });
  };

  const handleDeleteAffiliate = async (affiliateId: string) => {
    if (window.confirm('Are you sure you want to delete this affiliate? This action cannot be undone. This will delete all related records including notifications, referrals, and payouts.')) {
      await deleteAffiliateMutation.mutateAsync(affiliateId);
    }
  };

  const handleResendWelcomeEmail = async (app: AffiliateApplication) => {
    if (window.confirm(`Are you sure you want to resend the welcome email to ${app.first_name} ${app.last_name}?`)) {
      await resendWelcomeEmailMutation.mutateAsync(app.id);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center">
        <p className="text-red-500">Error loading affiliate applications</p>
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] })}
          className="mt-4 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
        >
          Retry Loading
        </motion.button>
      </div>
    );
  }

  const filteredApplications = getFilteredApplications();

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Affiliate Management</h1>
        <div className="flex items-center gap-4">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleExport}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
          >
            <Download className="w-4 h-4" />
            Export CSV
          </motion.button>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Affiliate Applications ({filteredApplications.length})
          </h2>
        </div>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-900">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Website
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {filteredApplications.map((app) => (
                <tr key={app.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {app.first_name} {app.last_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {app.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {app.website || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                      ${app.status === 'active' 
                        ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                        : app.status === 'pending'
                        ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                        : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'}`}>
                      {app.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex justify-end gap-2">
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleToggleStatus(app)}
                        className={`
                          ${app.status === 'active' 
                            ? 'text-yellow-600 hover:text-yellow-900 dark:text-yellow-400 dark:hover:text-yellow-300'
                            : 'text-green-600 hover:text-green-900 dark:text-green-400 dark:hover:text-green-300'}`}
                        title={app.status === 'active' ? 'Deactivate' : 'Activate'}
                      >
                        {app.status === 'active' ? <UserX className="w-4 h-4" /> : <UserCheck className="w-4 h-4" />}
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleDeleteAffiliate(app.id)}
                        className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                        title="Delete"
                      >
                        <Trash2 className="w-4 h-4" />
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleViewDetails(app)}
                        className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
                        title="View Details"
                      >
                        <Edit2 className="w-4 h-4" />
                      </motion.button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      {showDetailsDialog && selectedAffiliate && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeDetailsDialog}
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl max-w-2xl w-full mx-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-6 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                Affiliate Details
              </h2>
              <motion.button
                whileHover={{ rotate: 90 }}
                onClick={closeDetailsDialog}
                className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
              >
                <X className="w-6 h-6" />
              </motion.button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Personal Information
                </h3>
                <div className="space-y-3">
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Name</label>
                    <p className="text-gray-900 dark:text-white">
                      {selectedAffiliate.first_name} {selectedAffiliate.last_name}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Email</label>
                    <p className="text-gray-900 dark:text-white">{selectedAffiliate.email}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Website</label>
                    <p className="text-gray-900 dark:text-white">
                      {selectedAffiliate.website || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Affiliate Details
                </h3>
                <div className="space-y-3">
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Preferred Tier</label>
                    <p className="text-gray-900 dark:text-white capitalize">
                      {selectedAffiliate.preferred_tier}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Status</label>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      selectedAffiliate.status === 'pending'
                        ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                        : selectedAffiliate.status === 'active'
                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                        : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                    }`}>
                      {selectedAffiliate.status}
                    </span>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Applied At</label>
                    <p className="text-gray-900 dark:text-white">
                      {format(new Date(selectedAffiliate.created_at), 'MMMM d, yyyy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="p-6 border-t border-gray-200 dark:border-gray-700 flex justify-end space-x-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleResendWelcomeEmail(selectedAffiliate)}
                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                <Mail className="w-4 h-4" />
                Resend Welcome Email
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleToggleStatus(selectedAffiliate)}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                  selectedAffiliate.status === 'active'
                    ? 'bg-yellow-500 text-white hover:bg-yellow-600'
                    : 'bg-green-500 text-white hover:bg-green-600'
                }`}
              >
                {selectedAffiliate.status === 'active' ? 'Deactivate' : 'Activate'}
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default AffiliateManagement;
