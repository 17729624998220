import React, { useState, useRef, useEffect } from 'react';
import { MessageSquare, X, Send, Loader2 } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import { sendMessage } from '@services/core/api';
import { aiConfig } from '@services/config/ai';
import { recordChatAnalytics } from '@services/api/analytics';
import type { Message } from '@/types/chat';
import MarkdownMessage from '@components/common/MarkdownMessage';
import BotAvatar from '@components/chat/BotAvatar';

const AIChatButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '1',
      text: 'Hi there! 👋 What questions do you have about Scholar Tree?',
      isUser: false,
      timestamp: new Date()
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [rateLimitExceeded, setRateLimitExceeded] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const lastMessageTime = useRef<Date | null>(null);

  // Rate limiting constants
  const MAX_MESSAGES_PER_MINUTE = 5;
  const MAX_MESSAGES_PER_HOUR = 20;
  const RATE_LIMIT_RESET_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

  useEffect(() => {
    // Reset message count every hour
    const resetTimer = setInterval(() => {
      setMessageCount(0);
      setRateLimitExceeded(false);
    }, RATE_LIMIT_RESET_TIME);

    return () => clearInterval(resetTimer);
  }, []);

  const checkRateLimit = (): boolean => {
    const now = new Date();
    
    // Check messages per minute
    if (lastMessageTime.current) {
      const timeSinceLastMessage = now.getTime() - lastMessageTime.current.getTime();
      if (timeSinceLastMessage < 60000) { // Less than 1 minute
        if (messageCount >= MAX_MESSAGES_PER_MINUTE) {
          setError(`Please wait a moment before sending more messages. Maximum ${MAX_MESSAGES_PER_MINUTE} messages per minute.`);
          return true;
        }
      }
    }

    // Check messages per hour
    if (messageCount >= MAX_MESSAGES_PER_HOUR) {
      setRateLimitExceeded(true);
      setError(`Message limit exceeded. Please try again in ${Math.ceil(RATE_LIMIT_RESET_TIME / (60 * 60 * 1000))} hour.`);
      return true;
    }

    return false;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    // Check rate limits
    if (checkRateLimit()) {
      return;
    }

    // Update rate limiting state
    setMessageCount(prev => prev + 1);
    lastMessageTime.current = new Date();

    const userMessage: Message = {
      id: String(messages.length + 1),
      text: input.trim(),
      isUser: true,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setError(null);
    setIsLoading(true);

    const startTime = Date.now();
    const sessionId = sessionStorage.getItem('chatSessionId') || Math.random().toString(36).substring(7);
    
    if (!sessionStorage.getItem('chatSessionId')) {
      sessionStorage.setItem('chatSessionId', sessionId);
    }

    try {
      const response = await sendMessage(userMessage.text);
      const botMessage: Message = {
        id: String(messages.length + 2),
        text: response.content,
        isUser: false,
        timestamp: new Date()
      };
      setMessages(prev => [...prev, botMessage]);

      // Record successful chat analytics
      recordChatAnalytics({
        question: userMessage.text,
        answer: response.content,
        timestamp: new Date(),
        sessionId,
        responseTime: Date.now() - startTime,
        providerUsed: aiConfig.provider,
        modelUsed: aiConfig.selectedModel[aiConfig.provider],
        tokensUsed: response.tokensUsed
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
        // Record failed chat analytics
        recordChatAnalytics({
          question: userMessage.text,
          timestamp: new Date(),
          sessionId,
          responseTime: Date.now() - startTime,
          providerUsed: aiConfig.provider,
          modelUsed: aiConfig.selectedModel[aiConfig.provider],
          tokensUsed: 0,
          error: err.message
        });
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`fixed bottom-4 sm:bottom-16 right-4 sm:right-6 p-3 sm:p-4 bg-primary-500 hover:bg-primary-600 rounded-full shadow-lg transition-all duration-200 z-50 ${isOpen ? 'hidden sm:flex' : 'flex'}`}
      >
        {isOpen ? <X className="w-5 h-5 sm:w-6 sm:h-6 text-white" /> : <MessageSquare className="w-5 h-5 sm:w-6 sm:h-6 text-white" />}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            className="fixed bottom-4 sm:bottom-[140px] right-6 w-80 sm:w-96 h-[500px] sm:h-[600px] bg-gray-900 
              rounded-lg shadow-xl border-2 border-primary-500/50
              flex flex-col overflow-hidden z-50 mx-auto max-w-lg
              ring-1 ring-primary-500/20 backdrop-blur-sm"
          >
            <div className="p-4 border-b border-gray-700 bg-gray-800">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <BotAvatar />
                  <h3 className="text-lg font-bold text-white">AI Assistant</h3>
                </div>
                <button
                  onClick={() => setIsOpen(false)}
                  className="p-1.5 hover:bg-gray-700/50 rounded-full transition-all duration-200 sm:hidden group"
                  aria-label="Close chat"
                >
                  <X className="w-5 h-5 text-gray-400 group-hover:text-white transition-colors duration-200" />
                </button>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`flex gap-3 ${message.isUser ? 'flex-row-reverse' : ''}`}
                >
                  {!message.isUser && <BotAvatar />}
                  <div
                    className={`flex-1 max-w-max rounded-lg px-4 py-2 ${
                      message.isUser
                        ? 'bg-primary-500 text-white ml-auto'
                        : 'bg-gray-800 text-gray-100'
                    }`}
                  >
                    <MarkdownMessage content={message.text} />
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="flex gap-3">
                  <BotAvatar />
                  <div className="bg-gray-800 text-gray-100 rounded-lg p-3">
                    <div className="flex gap-1">
                      <span className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                      <span className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                      <span className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                    </div>
                  </div>
                </div>
              )}
              {error && (
                <div className="flex justify-center">
                  <div className="bg-red-500/10 text-red-400 rounded-lg p-3 text-sm">
                    {error}
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            <form onSubmit={handleSubmit} className="p-4 border-t border-gray-700 bg-gray-800 mt-auto">
              <div className="flex space-x-2">
                <input
                  type="text"
                  id="chat-input"
                  name="chat-input"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  disabled={rateLimitExceeded || isLoading}
                  placeholder={rateLimitExceeded ? "Message limit exceeded" : "Type your message..."}
                  className="flex-1 bg-gray-700 text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                <button
                  type="submit"
                  disabled={rateLimitExceeded || isLoading || !input.trim()}
                  className="bg-primary-500 text-white px-4 py-2 rounded-lg hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
                >
                  {isLoading ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    <Send className="w-5 h-5" />
                  )}
                </button>
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AIChatButton;
