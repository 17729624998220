import { type FC } from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { AuthProvider } from '@/components/auth/AuthProvider';
import RoleProtectedRoute from '@/components/auth/RoleProtectedRoute';
import Navbar from '@/components/layout/Navbar';
import Hero from '@/components/home/Hero';
import Features from '@/components/home/Features';
import Pricing from '@/components/home/Pricing';
import Footer from '@/components/layout/Footer';
import AIChatButton from '@/components/chat/AIChatButton';
import LoadingBar from '@/components/common/LoadingBar';
import UmamiAnalytics from '@/components/analytics/UmamiAnalytics';
import MainSEO from '@/components/seo/MainSEO';
import adminRoutes from '@/admin/routes';
import ScrollToTop from '@/components/common/ScrollToTop';
import { AffiliateLayout } from '@/components/layouts/AffiliateLayout';
import Blog from '@/pages/Blog';
import BlogPost from '@/pages/BlogPost';
import Affiliate from '@/pages/Affiliate';
import AffiliateLogin from '@/pages/affiliate/Login';
import AffiliateSignup from '@/pages/affiliate/Signup';
import AffiliateApplication from '@/pages/AffiliateApplication';
import AffiliateThankYou from '@/pages/AffiliateThankYou';
import AffiliateDashboard from '@/pages/AffiliateDashboard';
import AffiliateVerify from '@/pages/affiliate/Verify';
import ResetPassword from '@/pages/affiliate/ResetPassword';
import UpdatePassword from '@/pages/affiliate/UpdatePassword';
import AffiliateAssets from '@/pages/affiliate/Assets';
import AffiliateCode from '@/pages/affiliate/Code';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import NotFoundPage from '@/pages/error/404';
import ServerErrorPage from '@/pages/error/500';
import ForbiddenPage from '@/pages/error/403';

const queryClient = new QueryClient();

// Create the main layout component
const MainLayout: FC = () => (
  <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
    <LoadingBar />
    <MainSEO />
    <AnimatePresence mode="wait">
      <main>
        <Navbar />
        <Hero />
        <Features />
        <Pricing />
        <Footer />
      </main>
    </AnimatePresence>
    <AIChatButton />
  </div>
);

// Protected affiliate layout
const ProtectedAffiliateLayout: FC = () => (
  <RoleProtectedRoute requiredRoles="affiliate">
    <AffiliateLayout />
  </RoleProtectedRoute>
);

// Create router configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <Outlet />
      </>
    ),
    errorElement: <ErrorBoundary><ServerErrorPage /></ErrorBoundary>,
    children: [
      {
        path: "",
        element: <MainLayout />
      },
      {
        path: "blog",
        children: [
          {
            path: "",
            element: <Blog />
          },
          {
            path: ":slug",
            element: <BlogPost />
          }
        ]
      },
      {
        path: "affiliate",
        children: [
          {
            path: "",
            element: <Affiliate />
          },
          {
            path: "verify",
            element: <AffiliateVerify />
          },
          {
            path: "login",
            element: <AffiliateLogin />
          },
          {
            path: "signup",
            element: <AffiliateSignup />
          },
          {
            path: "reset-password",
            element: <ResetPassword />
          },
          {
            path: "update-password",
            element: <UpdatePassword />
          },
          {
            path: "apply",
            element: <AffiliateApplication />
          },
          {
            path: "thank-you",
            element: <AffiliateThankYou />
          },
          {
            element: <ProtectedAffiliateLayout />,
            children: [
              {
                path: "dashboard",
                element: <AffiliateDashboard />
              },
              {
                path: "assets",
                element: <AffiliateAssets />
              },
              {
                path: "code",
                element: <AffiliateCode />
              },
              {
                path: "performance",
                element: <AffiliateDashboard />
              }
            ]
          }
        ]
      },
      {
        path: "admin",
        children: adminRoutes
      },
      {
        path: "403",
        element: <ForbiddenPage />
      },
      {
        path: "*",
        element: <NotFoundPage />
      }
    ]
  }
]);

const App: FC = () => {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router} />
          <Toaster position="top-right" />
          <UmamiAnalytics />
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;