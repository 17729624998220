import { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { 
  Download, 
  Trash2, 
  FileText, 
  FileImage, 
  FileArchive, 
  FileCode, 
  File 
} from 'lucide-react';
import { adminStorageApi } from '@/services/api/adminStorage';
import { supabase } from '@/lib/supabaseClient';

const getFileIcon = (fileName: string) => {
  const ext = fileName.split('.').pop()?.toLowerCase();
  switch (ext) {
    case 'txt': return FileText;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif': return FileImage;
    case 'zip':
    case 'rar':
    case '7z': return FileArchive;
    case 'js':
    case 'ts':
    case 'tsx':
    case 'jsx':
    case 'py':
    case 'html':
    case 'css': return FileCode;
    default: return File;
  }
};

const AdminStorage = () => {
  const queryClient = useQueryClient();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const { data: files, isLoading } = useQuery({
    queryKey: ['admin-storage-files'],
    queryFn: () => adminStorageApi.listFiles()
  });

  const uploadMutation = useMutation({
    mutationFn: (file: File) => adminStorageApi.uploadFile(file),
    onSuccess: () => {
      toast.success('File uploaded successfully');
      queryClient.invalidateQueries({ queryKey: ['admin-storage-files'] });
    },
    onError: (error) => {
      toast.error('Failed to upload file');
      console.error(error);
    }
  });

  const deleteMutation = useMutation({
    mutationFn: (path: string) => adminStorageApi.deleteFile(path),
    onSuccess: () => {
      toast.success('File deleted successfully');
      queryClient.invalidateQueries({ queryKey: ['admin-storage-files'] });
      setSelectedFiles([]);
    },
    onError: (error) => {
      toast.error('Failed to delete file');
      console.error(error);
    }
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(file => {
      uploadMutation.mutate(file);
    });
  }, [uploadMutation]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    multiple: true
  });

  const handleFileSelect = (path: string) => {
    setSelectedFiles(prev => 
      prev.includes(path) 
        ? prev.filter(p => p !== path)
        : [...prev, path]
    );
  };

  const handleDeleteSelected = () => {
    selectedFiles.forEach(path => {
      deleteMutation.mutate(path);
    });
  };

  const handleDownload = async (path: string, fileName: string) => {
    try {
      // Get a signed URL for the file
      const { data, error } = await supabase.storage
        .from('admin-storage')
        .createSignedUrl(path, 60); // URL valid for 60 seconds

      if (error) {
        toast.error('Failed to generate download link');
        console.error('Download error:', error);
        return;
      }

      if (!data?.signedUrl) {
        toast.error('No download link available');
        return;
      }

      // Create a temporary link and trigger download
      const link = document.createElement('a');
      link.href = data.signedUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error('Download failed');
      console.error('Download error:', error);
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Admin Storage
          </h1>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
            Manage and organize your files securely
          </p>
        </div>
        {selectedFiles.length > 0 && (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleDeleteSelected}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200"
          >
            <Trash2 className="w-4 h-4 inline-block mr-2" />
            Delete Selected ({selectedFiles.length})
          </motion.button>
        )}
      </div>

      <div 
        {...getRootProps()}
        className={`
          border-2 border-dashed rounded-lg p-8 text-center mb-8 transition-colors
          ${isDragActive 
            ? 'border-primary-500 bg-primary-50 dark:bg-primary-900/20' 
            : 'border-gray-300 dark:border-gray-600 hover:border-primary-500'
          }
        `}
      >
        <input {...getInputProps()} />
        <p className="text-gray-600 dark:text-gray-400">
          {isDragActive 
            ? 'Drop files here...' 
            : 'Drag \'n\' drop files here, or click to select files'}
        </p>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center">
          <motion.div 
            animate={{ rotate: 360 }}
            transition={{ repeat: Infinity, duration: 1 }}
          >
            <Download className="w-8 h-8 text-gray-500" />
          </motion.div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {files?.map((file) => {
            const FileIcon = getFileIcon(file.name);
            return (
              <motion.div 
                key={file.path}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.02 }}
                className="bg-white dark:bg-white/5 rounded-lg p-4 hover:bg-gray-50 
                  dark:hover:bg-white/10 transition-colors duration-200 shadow-sm"
              >
                <div className="flex items-center gap-4">
                  <input 
                    type="checkbox" 
                    checked={selectedFiles.includes(file.path)}
                    onChange={() => handleFileSelect(file.path)}
                    className="flex-shrink-0 w-4 h-4 text-primary-500 
                      border-gray-300 rounded focus:ring-primary-500"
                  />
                  <FileIcon className="w-8 h-8 text-gray-500 flex-shrink-0" />
                  <div className="min-w-0 flex-grow">
                    <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                      {file.name}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      {new Date(file.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    onClick={() => handleDownload(file.path, file.name)}
                    className="p-2 rounded-lg bg-blue-50 dark:bg-blue-500/20 
                      text-blue-600 dark:text-blue-400 hover:bg-blue-100 
                      dark:hover:bg-blue-500/30 transition-colors duration-200 
                      flex-shrink-0"
                  >
                    <Download className="w-4 h-4" />
                  </motion.button>
                </div>
              </motion.div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AdminStorage;
