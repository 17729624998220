import React, { useState, useRef, useEffect } from 'react';
import { Menu, X, GraduationCap, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');
  const [showAffiliateDropdown, setShowAffiliateDropdown] = useState(false);
  const { scrollY } = useScroll();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const affiliateDropdownRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const TOP_THRESHOLD = 50; // Threshold for considering the page "at top"

  const navBackground = useTransform(
    scrollY,
    [0, 100],
    ["rgba(255, 255, 255, 0.01)", "rgba(255, 255, 255, 0.05)"]
  );
  
  const navY = useTransform(scrollY, [0, 30], [20, 0]);
  const navPadding = useTransform(scrollY, [0, 15], [16, 2]);

  const handleClickOutside = (event: MouseEvent) => {
    if (affiliateDropdownRef.current && !affiliateDropdownRef.current.contains(event.target as Node)) {
      setShowAffiliateDropdown(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const toggleNavbar = () => {
    setScrollDirection('up');
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsScrolled(currentScrollY > TOP_THRESHOLD);
    
    // Only hide navbar when scrolling down significantly
    if (currentScrollY > TOP_THRESHOLD + 100) {
      setScrollDirection(currentScrollY > lastScrollY.current ? 'down' : 'up');
    } else {
      setScrollDirection('up');
    }
    
    lastScrollY.current = currentScrollY;
  };

  const lastScrollY = useRef<number>(window.scrollY);

  useEffect(() => {
    handleScroll(); // Initialize scroll state
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isAffiliateSection = location.pathname.startsWith('/affiliate');

  return (
    <>
      <div className={`fixed top-0 left-0 right-0 z-[100] px-4 sm:px-6 lg:px-8 transition-all duration-300 ${
        scrollDirection === 'down' && isScrolled ? '-translate-y-full' : 'translate-y-0'
      }`} style={{ paddingTop: `${navPadding.get()}px` }}>
        <div className="mx-auto max-w-7xl">
          <motion.nav
            ref={menuRef}
            style={{ 
              y: navY,
              backgroundColor: navBackground,
            }}
            className={`
              rounded-2xl backdrop-blur-md border border-white/10
              transition-shadow duration-300 ${isScrolled ? 'shadow-2xl shadow-black/20' : 'shadow-lg shadow-black/10'}
            `}
            aria-label="Main navigation"
          >
            <div className="px-6 sm:px-8 lg:px-12">
              <div className="flex justify-between h-14">
                <motion.div 
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="flex items-center gap-2"
                >
                  <Link to="/" className="flex items-center gap-2">
                    <GraduationCap className="h-6 w-6 text-white" />
                    <h1 className="text-2xl font-bold text-white">
                      Scholar Tree
                    </h1>
                  </Link>
                </motion.div>
                
                <motion.div 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="hidden md:flex items-center space-x-8"
                >
                  {location.pathname === '/' ? (
                    <>
                      <a 
                        href="#features" 
                        className="text-primary-200 hover:text-primary-400 transition-colors duration-200"
                        aria-label="View features section"
                      >
                        Features
                      </a>
                      <a 
                        href="#pricing" 
                        className="text-primary-200 hover:text-primary-400 transition-colors duration-200"
                        aria-label="View pricing section"
                      >
                        Pricing
                      </a>
                    </>
                  ) : (
                    <>
                      <Link 
                        to="/#features" 
                        className="text-primary-200 hover:text-primary-400 transition-colors duration-200"
                        aria-label="View features section"
                      >
                        Features
                      </Link>
                      <Link 
                        to="/#pricing" 
                        className="text-primary-200 hover:text-primary-400 transition-colors duration-200"
                        aria-label="View pricing section"
                      >
                        Pricing
                      </Link>
                    </>
                  )}
                  <Link 
                    to="/blog" 
                    className={`${
                      location.pathname.startsWith('/blog') 
                        ? 'text-accent-500' 
                        : 'text-primary-200 hover:text-primary-400'
                    } transition-colors duration-200`}
                    aria-label="Visit our blog"
                  >
                    Blog
                  </Link>
                  <div className="relative" ref={affiliateDropdownRef}>
                    <button
                      onClick={() => setShowAffiliateDropdown(!showAffiliateDropdown)}
                      className={`${
                        isAffiliateSection
                          ? 'text-accent-500'
                          : 'text-primary-200 hover:text-primary-400'
                      } transition-colors duration-200 flex items-center gap-1`}
                      aria-expanded={showAffiliateDropdown}
                      aria-controls="affiliate-dropdown"
                      aria-label="Affiliate program menu"
                    >
                      Affiliates
                      <ChevronDown className={`w-4 h-4 transition-transform duration-200 ${showAffiliateDropdown ? 'rotate-180' : ''}`} aria-hidden="true" />
                    </button>
                    <AnimatePresence>
                      {showAffiliateDropdown && (
                        <motion.div
                          id="affiliate-dropdown"
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          className="absolute right-0 mt-2 w-48 rounded-xl bg-gray-900/90 backdrop-blur-lg shadow-xl border border-white/10"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="affiliate-menu-button"
                        >
                          <div className="py-2">
                            <Link
                              to="/affiliate"
                              onClick={() => setShowAffiliateDropdown(false)}
                              className={`block px-4 py-2 text-sm ${
                                location.pathname === '/affiliate'
                                  ? 'text-accent-500'
                                  : 'text-primary-200 hover:text-primary-400'
                              } transition-colors duration-200`}
                              role="menuitem"
                            >
                              Overview
                            </Link>
                            <Link
                              to="/affiliate/apply"
                              onClick={() => setShowAffiliateDropdown(false)}
                              className={`block px-4 py-2 text-sm ${
                                location.pathname === '/affiliate/apply'
                                  ? 'text-accent-500'
                                  : 'text-primary-200 hover:text-primary-400'
                              } transition-colors duration-200`}
                              role="menuitem"
                            >
                              Apply
                            </Link>
                            <Link
                              to="/affiliate/login"
                              onClick={() => setShowAffiliateDropdown(false)}
                              className={`block px-4 py-2 text-sm ${
                                location.pathname === '/affiliate/login'
                                  ? 'text-accent-500'
                                  : 'text-primary-200 hover:text-primary-400'
                              } transition-colors duration-200`}
                              role="menuitem"
                            >
                              Login
                            </Link>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                  <div className="flex items-center">
                    <motion.a
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      href="https://testflight.apple.com/join/aGnXvabx"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gradient-to-r from-accent-500 to-accent-600 hover:from-accent-400 hover:to-accent-500 
                        transition-all duration-200 text-white px-6 py-2 rounded-xl shadow-lg 
                        shadow-accent-500/20 hover:shadow-accent-500/40 hover:-translate-y-0.5
                        flex items-center gap-2"
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"/>
                      </svg>
                      <span className="hidden lg:inline">Prelaunch Beta</span>
                      <span className="lg:hidden"></span>
                    </motion.a>
                  </div>
                </motion.div>

                <div className="md:hidden flex items-center space-x-4">
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsOpen(!isOpen)}
                    className="p-2 text-primary-200 hover:text-primary-400 transition-colors duration-200"
                    aria-controls="mobile-menu"
                    aria-expanded={isOpen}
                    aria-label="Toggle navigation menu"
                  >
                    <span className="sr-only">Open main menu</span>
                    {isOpen ? <X className="h-6 w-6" aria-hidden="true" /> : <Menu className="h-6 w-6" aria-hidden="true" />}
                  </motion.button>
                </div>
              </div>
            </div>

            <AnimatePresence>
              {isOpen && (
                <motion.div
                  id="mobile-menu"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="lg:hidden absolute top-full left-0 w-full bg-gray-900/95 backdrop-blur-sm"
                  role="dialog"
                  aria-modal="true"
                  aria-label="Mobile navigation menu"
                >
                  <div className="px-2 pt-2 pb-3 space-y-1" role="menu">
                    {location.pathname === '/' ? (
                      <>
                        <a
                          href="#features"
                          className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary-400"
                          role="menuitem"
                          onClick={() => setIsOpen(false)}
                        >
                          Features
                        </a>
                        <a
                          href="#pricing"
                          className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary-400"
                          role="menuitem"
                          onClick={() => setIsOpen(false)}
                        >
                          Pricing
                        </a>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/#features"
                          className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary-400"
                          role="menuitem"
                          onClick={() => setIsOpen(false)}
                        >
                          Features
                        </Link>
                        <Link
                          to="/#pricing"
                          className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary-400"
                          role="menuitem"
                          onClick={() => setIsOpen(false)}
                        >
                          Pricing
                        </Link>
                      </>
                    )}
                    <Link
                      to="/blog"
                      className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary-400"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Blog
                    </Link>
                    <Link
                      to="/affiliate"
                      className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary-400"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Affiliate Program
                    </Link>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.nav>
        </div>
      </div>
      {scrollDirection === 'down' && (
        <button
          onClick={toggleNavbar}
          className="fixed top-0 left-1/2 transform -translate-x-1/2 z-50 bg-gray-800 bg-opacity-50 text-white px-2 py-0.5 rounded-b-md shadow-md hover:bg-opacity-70 transition-opacity duration-200"
        >
          ▼
        </button>
      )}
    </>
  );
};

export default Navbar;