import React from 'react';
import { Helmet } from 'react-helmet-async';
import type { BlogPost } from '@/types/blog';

interface BlogSEOProps {
  post?: BlogPost;
  isBlogList?: boolean;
}

const BlogSEO: React.FC<BlogSEOProps> = ({ post, isBlogList = false }) => {
  const baseUrl = 'https://scholartree.app';
  const defaultDescription = 'Explore educational insights, teaching strategies, and academic resources on the Scholar Tree blog.';
  const defaultImage = `${baseUrl}/images/og-image.jpg`;

  // For individual blog post
  const articleStructuredData = post ? {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: post.title,
    description: post.excerpt,
    image: post.featured_image || defaultImage,
    datePublished: post.published_at,
    dateModified: post.updated_at || post.published_at,
    author: {
      '@type': 'Person',
      name: post.author?.name || 'Scholar Tree Team'
    },
    publisher: {
      '@type': 'Organization',
      name: 'Scholar Tree',
      logo: {
        '@type': 'ImageObject',
        url: `${baseUrl}/images/logo.png`
      }
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${baseUrl}/blog/${post.slug}`
    },
    keywords: post.tags?.join(', ')
  } : null;

  // For blog list
  const blogStructuredData = isBlogList ? {
    '@context': 'https://schema.org',
    '@type': 'Blog',
    name: 'Scholar Tree Blog',
    description: defaultDescription,
    url: `${baseUrl}/blog`,
    publisher: {
      '@type': 'Organization',
      name: 'Scholar Tree',
      logo: {
        '@type': 'ImageObject',
        url: `${baseUrl}/images/logo.png`
      }
    }
  } : null;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>
        {post ? `${post.title} | Scholar Tree Blog` : 'Scholar Tree Blog | Educational Insights & Resources'}
      </title>
      <meta
        name="description"
        content={post?.excerpt || defaultDescription}
      />

      {/* Open Graph Tags */}
      <meta
        property="og:title"
        content={post ? post.title : 'Scholar Tree Blog'}
      />
      <meta
        property="og:description"
        content={post?.excerpt || defaultDescription}
      />
      <meta
        property="og:image"
        content={post?.featured_image || defaultImage}
      />
      <meta
        property="og:url"
        content={post ? `${baseUrl}/blog/${post.slug}` : `${baseUrl}/blog`}
      />
      <meta property="og:type" content={post ? 'article' : 'website'} />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={post ? post.title : 'Scholar Tree Blog'}
      />
      <meta
        name="twitter:description"
        content={post?.excerpt || defaultDescription}
      />
      <meta
        name="twitter:image"
        content={post?.featured_image || defaultImage}
      />

      {/* Article Meta Tags */}
      {post && (
        <>
          <meta property="article:published_time" content={post.published_at} />
          {post.updated_at && (
            <meta property="article:modified_time" content={post.updated_at} />
          )}
          {post.tags?.map((tag) => (
            <meta key={tag} property="article:tag" content={tag} />
          ))}
        </>
      )}

      {/* Structured Data */}
      {articleStructuredData && (
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      )}
      {blogStructuredData && (
        <script type="application/ld+json">
          {JSON.stringify(blogStructuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default BlogSEO;
